/* intial */
.TestimonailsLogo{
  width: 100px;
}
.carousel-control-next,
.carousel-control-prev {
  z-index: 500 !important;
  background-color: black !important;
  height: 50px !important;
  margin: auto !important;
  border-radius: 50% !important;
  width: 50px !important;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  scroll-behavior: smooth;
}
h1,
h2,
h3,
h4,
h5,
h6,
.card-title {
  font-family: "Roboto Slab", serif !important;
}

.card-img-top {
  height: auto;
}
body {
  font-family: "Noto Sans", sans-serif !important;
  background-color: #070f2b !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.large-hide {
  display: none !important ;
}
.text-small {
  font-size: x-small;
}
.blue-text {
  color: #047c93;
}
.large-hide-call-btn {
  display: none !important;
}
/* index -  navbar */
nav {
  background-color: black !important;
}
.navbar {
  background-color: transparent;
}
.navbar li .nav-link {
  color: white;
}
nav li:hover .nav-link {
  color: orange !important;
}
/* index- home */
#home {
  /* background-image: url(assets/bg-last.jpg); */
  /* min-height: 80vh; */
  background-size: 100%;
  background-image: linear-gradient(black, #071640);
}
#typing {
  /* font-size: 24px; */
  color: orange;
  font-family: Arial, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.15em solid orange;
  animation: typing 3s steps(30, end), blink-caret 0.5s step-end infinite;
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}
/* index - showcase */
#show-case {  
  background-color: #071640;
}
/* index-  about us */
.GifHomeImg{
  width:280px !important;
}
#about-us{
  background-color: #071640;
}
.text-head {
  font-family: "Protest Riot", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.front {
  transform: rotateY(0deg); /* Initially show the front side */
  transition: transform 0.5s; /* Add transition for smooth flipping */
}
.back {
  position: absolute;
  z-index: -1000;
  bottom: 50%;
  top: 0%;
  height: 100%;
  width: 100%;
  background-color: rgb(255, 255, 255);
  transform: rotateY(180deg); /* Initially hide the back side */
  transition: transform 0.5s; /* Add transition for smooth flipping */
  /* display: none; */
}
.card:hover .front {
  transform: rotateY(-180deg); /* Rotate the front side on hover */
  cursor: pointer;
}
.card:hover .back {
  transform: rotateY(0deg);
  z-index: 100;
}
::-webkit-scrollbar {
  width: 0; /* You can also use 'height: 0;' depending on the orientation */
}
/* index - services */
#services {
  background-image: url(/src/assets/service-bg.gif);
  background-attachment: fixed;
}
.service-card {
  background-color: #091d40 !important;
  border: none;
  color: white !important;
}
/* index-testimonials */
#testimonials {
  background-color: #091d40;
}
/*index , pricing - our blog */
.pricing .card,
#blog .card {
  background-color: #133776 !important;
  color: white;
}
#pricing-home {
  background-image: linear-gradient(black, #070f2b) !important;
}
/* index - how it works */
.scrolling-div {
  display: flex;
  overflow-x: scroll;
  /* white-space: nowrap; */
  color: white;
}
.scrolling-div div {
  width: 20%;
}
.scrolling-div p {
  font-size: 12px;
}
.path-img {
  width: 75%;
}
.scrolling-div .col-4 {
  padding: 20px 10px !important;
  border-radius: 10px;
}
.scrolling-div .col-4:hover {
  background-color: rgb(0, 48, 78);
  padding: 20px 10px !important;
}
/*index - contactus */
.min-card {
  max-width: 500px;
}
#contact-us {
  background-image: url(/src/assets/wave-img.png);
}
.bg-blue {
  background-color: #0d295b !important;
  border: none;
}
.bg-blue-light {
  background-color: #133776 !important;
  color: white !important;
  border: none !important;
}
.bg-blue-light::placeholder {
  color: white !important;
}

@media (max-width: 768px) {
  .large-hide-call-btn {
    display: block !important;
  }
  .small-hide-call-btn {
    display: none !important;
  }
  .scrolling-div div {
    width: 30%;
  }
}
@media (max-width: 530px) {
  .small-hide,
  .break {
    display: none !important;
  }
  .large-hide {
    display: block !important;
  }
  .scrolling-div div {
    width: 50%;
  }
  .path-img {
    width: 100%;
  }
}

/* website- webhome */
#web-home {
  background-image: linear-gradient(black, rgb(8, 8, 83));
  min-height: 90vh;
  background-size: cover;
}
.laptop-img {
  max-width: 40%;
  position: relative;
  right: -15%;
}
.gif-img {
  width: 30%;
  height: 62%;
  position: relative;
  z-index: 1000;
  top: 5%;
  left: -20%;
}
@media (max-width: 540px) {
  .laptop-img {
    max-width: 75%;
    position: relative;
    right: -28%;
  }
  .gif-img {
    width: 55%;
    height: 61%;
    position: relative;
    z-index: 1000;
    top: 5%;
    left: -37%;
  }
}

/* app- apphome */
#app-home {
  background-image: linear-gradient(black, #1b025e);
  /* min-height: 90vh; */
  background-size: cover;
}
#about-app,
#app-projects {
  background-color: #1b025e;
}
.bg-purple {
  background-color: #10003d !important;
}

/* digital- digitalhome */
#digital-home {
  background-image: linear-gradient(black, #0e0e10);
  background-size: cover;
}
.digital-img {
  max-width: 50%;
}
#about-digital,
#digital-projects {
  background-color: #0e0e10;
}
.bg-digital {
  background-color: #010e42 !important;
}
@media (max-width: 540px) {
  .digital-img {
    max-width: 100%;
  }
}

/* graphic design */
#about-graphic,
#graphic-contact {
  background-image: url(/src/assets/graphic-bg.jpg);
  background-size: cover;
  background-attachment: fixed;
}
.heading-graphic {
  background-image: linear-gradient(#1afff7, #2e20c8, #951bdb);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
.bg-yellow {
  background-image: linear-gradient(#0b9f9a, #150b8a, #65059d) !important;
  border: none !important;
}
.btn-graphic {
  background-image: linear-gradient(#1afff7, #2e20c8, #951bdb) !important;
  border: none !important;
  color: white !important;
}
/* work */
#work-home {
  background-image: linear-gradient(black, rgb(8, 64, 83));
}

.custom-nav-link:active {
  color: white !important;
}


.custom-dropdown-item:hover {
  background: transparent !important;
  text-decoration: underline; 
}